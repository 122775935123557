<template>
  <div>

    <Header>
      <template #right-header-component>
        <ProfileDropDown/>

      </template>
    </Header>

    <div  style="margin-top: 55px" >
      <div>
        <Loader v-if="loading"/>
      </div>
      <div v-if="!loading">
        <div class="tw-h-44 d-flex align-center  justify-center cardHero tw-w-full">
          <div class=" px-4 md:tw-w-3/6 tw-w-full">
            <div class="d-flex flex-wrap justify-space-between ">
              <h3 class="welcome text-capitalize">Welcome Back, {{ name }}</h3>
<!--                <v-btn id="createOrganization" @click="goToCreateProfile()" color="transparent" class="d-flex tw-mt-2 md:tw-mt-0 btn text-capitalize">-->
<!--                  <PlusIcon/>-->
<!--                  <h4 class="create">Create Organization</h4>-->
<!--                </v-btn>-->

            </div>
          </div>
        </div>
        <div class="d-flex mt-n10 flex-column align-center  justify-center">
          <div id="companyDetails" class="scroll  tw-max-h-3/5 md:tw-max-h-3/5 px-4 md:tw-w-3/6 tw-w-full">
            <div v-for="(companyDetail, index) in allCompanies" :key="index">
              <div style="cursor: pointer" @click="getSiteName(companyDetail)">
                <ViewAllOrganizationCard :company-details="companyDetail"/>
              </div>
            </div>
          </div>
        </div>
        <v-footer app bottom color="white" fixed padless class="py-5 tw-text-sm md:tw-text-base d-flex align-center  justify-center cardHero tw-w-full">
          <div class=" px-4 md:tw-w-3/6 tw-w-full">
            <div class="d-flex flex-wrap align-center tw-mt-2 md:tw-mt-12  justify-center footer">
              <h3 class="findText">Can’t find your organization?</h3>
              <div style="cursor: pointer" class="d-flex ml-3 align-center signIn" @click="goToSignIn()">
                <h3 class="mr-1">Sign in with a different email</h3>
                <ArrowRightIcon size="18" class="arrow-class"/>
              </div>
            </div>
          </div>
        </v-footer>

      </div>

    </div>
  </div>
</template>

<script>
import Header from "@/components/onboarding/reuseables/Header";
import ViewAllOrganizationCard from "@/components/onboarding/components/viewAllOrganization/ViewAllOrganizationCard";
import {getAllCompanies} from "@/services/api/APIService";
import { ArrowRightIcon} from "vue-feather-icons"
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import {mapGetters} from "vuex";
import {viewFile} from "@/services/storage/aws";

export default {
name: "ViewAllOrganizationPage",
  components:{Loader, ProfileDropDown, ViewAllOrganizationCard, Header, ArrowRightIcon},
  data(){
    return {
      loading:false,
      allCompanies: []
    }
  },
  created() {
  this.loading = true
    getAllCompanies()
        .then(async res=>{
          for (const company of res.data) {
            console.log(company)
            await viewFile({url: company.company_logo, context: company, property: 'company_logo'});
          }
          console.log(res.data)
            this.allCompanies = res.data
          this.loading =false
        console.log(this.allCompanies)})
        .catch(err=>{console.log(err)
        this.loading = false})
  },
  computed:{
  ...mapGetters("onBoarding",["name"]),
    getUserTokenData(){
      return JSON.parse(sessionStorage.getItem('userData'))
    }
  },
  methods:{
    goToSignIn(){
      this.$router.push({name: "Login"})
    },
    goToCreateProfile(){
      this.$router.push({name: "CreateCompanyProfileView", params:{step: "1"}})
    },
    async getSiteName(companyDetail){
      console.log(companyDetail.company_name)
      console.log(companyDetail.site_name)
      let userToken = {...this.getUserTokenData}
      console.log(userToken)
      userToken.site_name = companyDetail.site_name
      sessionStorage.setItem("userData", JSON.stringify(userToken));
      await this.$router.push({name: 'RecruiterDashboard'})
    }
  }
}
</script>

<style scoped>
.cardHero{
  background: #EEF8FF;
}
.welcome{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #000000
}
.create{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #1E323F;
  letter-spacing: -0.02em
}
.scroll {
  /*max-height: 57vh;*/
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 15px !important;
  border-radius: 6px;
}
.btn{
  /*margin: 0 !important;*/
  padding: 0 !important;
}
.findText{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;

}
.signIn{
  color: #008EEF;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
}
.main__content{
  height: calc(60vh - 64px);
}
</style>