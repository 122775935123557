<template>
<div>
<div class="d-flex my-2 px-5 align-center justify-space-between card">
  <div class="d-flex align-center ">
    <div v-if="companyDetails.company_logo ">
    <img :src="companyDetails.company_logo" class="rounded">
    </div>
    <div v-else>
      <v-avatar color="#8F47F4" height="56" width="56" class="text-capitalize avatarText white--text rounded"
                tile>{{getInitail(companyDetails.company_name)}}</v-avatar>
    </div>
    <div class="pt-4 ml-4">
      <h3 class="text-capitalize">{{ companyDetails.company_name }}</h3>
      <p class="members mt-1">{{ companyDetails.members }} Member{{companyDetails.members > 1? "s": ""}}</p>
    </div>
  </div>

  <div>
    <v-btn depressed color="transparent" ><ArrowRightIcon/></v-btn>
  </div>


</div>
</div>
</template>

<script>
import {ArrowRightIcon} from "vue-feather-icons"

export default {
  name: "ViewAllOrganizationCard",
  components:{ArrowRightIcon},
  props:{
    companyDetails: Object
  },
  methods:{
    getInitail(name){
      let initial =name.substring(0,1)
      return initial
    }
  }

}
</script>

<style scoped>
img{
  height: 56px !important;
  width: 56px !important;
}
.card{
  /*width: 700px;*/
  height: 110px;
  background: #FFFFFF;
  border: 1px solid #F4F6F7;
  box-sizing: border-box;
  border-radius: 10px;
}
h3{
  color: #1E323F;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
}
.avatarText {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.members{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #9CABB5;
}

</style>