<template>
  <div>
    <div >
      <v-menu left transition="scroll-x-reverse-transition" @input="sheet=true" offset-y rounded :close-on-content-click="closeOnContent">
        <template v-slot:activator="{ attrs, on }">
          <div  v-bind="attrs" v-on="on" class="tw-flex tw-items-center tw-cursor-pointer">
            <AvatarIcon v-if="!getCompanyProfile.company_logo"/>
            <v-avatar
                v-else
                color="#EEF8FF"
                size="40">
              <img width="72" height="72"
                   :src="getCompanyProfile.company_logo"
                   alt="image" v-if="getCompanyProfile.company_logo" >
<!--              <span v-else>{{ getFirstName[0].charAt(0) + getFirstName[1].charAt(0)}}</span>-->

            </v-avatar>
            <span class="name tw-mx-3 tw-hidden lg:tw-block md:tw-block text-capitalize">{{ getFirstName[0] }}</span>
            <vue-feather type="chevron-down" class="tw-ml-2" style="color: #475661"/>
          </div>
        </template>
        <div v-if="!$vuetify.breakpoint.mobile && $router.currentRoute.name!=='ViewAllOrganizationPage'" >
          <v-list v-if="showOrganization" class="assigned">
            <ArrowLeftIcon class="tw-pl-2" @click="showOrganization = false"/>
            <small class="tw-pt-2">SWITCH APP</small>
            <v-list-item
                v-for="(app, i) in apps"
                :key="i"
                link
                :href="isActive(app.route) ? undefined : app.route"
            >
              <div class="tw-flex">
                <div class="tw-pr-2">
                  <div v-if="app.icon" class="app--icon d-flex justify-center align-center">
                    <img width="25" :src="app.icon" class=" rounded" :alt="app.name">
                  </div>
                </div>
                <v-list-item-title v-text="app.name" class="tw-flex tw-w-48 text-capitalize"></v-list-item-title>
              </div>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item
                v-for="item in showData"
                :key="item.name"
                link
            >
              <vue-feather :type="item.logo" style="color: #9CABB5;" />
              <v-list-item-content v-text="item.name" class="tw-flex pl-3 tw-w-48"
                                 @click="handleLogout(item.name)"></v-list-item-content>
              <vue-feather v-show="item.name === 'Switch Organization'" type="arrow-right" class="ml-n3"/>


            </v-list-item>
          </v-list>
        </div>
        <div v-if="$vuetify.breakpoint.mobile && $router.currentRoute.name!=='ViewAllOrganizationPage'"  style="box-shadow: none">
          <v-bottom-sheet
              v-model="sheet"
              hide-overlay
              class="radCard"
          >
            <v-card class="radCard">
              <div class="tw-pl-5 tw-mt-10 tw-flex">
                <v-avatar color="#EEF8FF"
                          size="30"
                          class="blue--text rounded-circle"
                          tile>{{
                    getFirstName[0].charAt(0).toUpperCase()
                  }}{{ getFirstName[1].charAt(0).toUpperCase() }}
                </v-avatar>
                <div class="tw-flex-wrap wrapText">
                  <small class="tw-font-">{{ getFirstName[0] }} {{ getFirstName[1] }}</small>
                  <small class="tw-pl-4 pt-n4">{{ getUserDetails.email }}</small>
                </div>
              </div>
              <v-list v-if="showOrganization" class="assigned">
                <ArrowLeftIcon class="tw-pl-2" @click="showOrganization = false"/>
                <small class="tw-pt-2">SWITCH APP</small>
                <v-list-item
                    v-for="(app, i) in apps"
                    :key="i"
                    :href="isActive(app.route) ? undefined : app.route"
                    link
                >
                  <div class="tw-flex">
                    <div class="tw-pr-2">
                      <div v-if="app.icon" class="app--icon d-flex justify-center align-center">
                        <img width="25" :src="app.icon" class=" rounded" :alt="app.name">
                      </div>
                    </div>
                    <v-list-item-title v-text="app.name" class="tw-flex tw-w-48 text-capitalize"></v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item
                    v-for="item in items"
                    :key="item.name"
                    link
                >
                  <vue-feather :type="item.logo" style="color: #9CABB5" />
                  <v-list-item-content v-text="item.name" class="tw-flex pl-3 tw-w-48"
                                     @click="handleLogout(item.name)"></v-list-item-content>
                  <vue-feather v-show="item.name === 'Switch App'" type="arrow-right" class="ml-n3"/>

                </v-list-item>
              </v-list>
            </v-card>
          </v-bottom-sheet>
        </div>
        <div v-if="$router.currentRoute.name==='ViewAllOrganizationPage'">
          <v-list>
            <v-list-item
                v-for="item in itemWithLogoutOnly"
                :key="item.name"
                link
            >
              <vue-feather :type="item.logo" style="color: #9CABB5;" />
              <v-list-item-content v-text="item.name" class="tw-flex pl-3 tw-w-48"
                                   @click="[handleLogout(item.name)]"></v-list-item-content>
              <vue-feather v-show="item.name === 'Switch App'" type="arrow-right" class="ml-n3"/>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>

    </div>
  </div>
</template>

<script>
import AvatarIcon from "@/components/dashboard/reuseables/AvatarIcon";
import {ArrowLeftIcon} from "vue-feather-icons";
import {logout} from "@/services/api/AuthApiService"
import {mapGetters} from "vuex";
import manage_learning from "@/assets/manage_learning.svg"
// import recruitment from "@/assets/recruitment.svg"

export default {
  name: "ProfileDropDown",
  components: {AvatarIcon, ArrowLeftIcon},
  data() {
    return {
      sheet: false,
      closeOnContent: false,
      items: [
        {
          name: "Switch App",
          logo: 'refresh-cw'
        },
        // {
        //   name: "View my Profile",
        //   logo: 'user'
        // },
        {
          name: "Admin Settings",
          logo: 'settings'
        },{
          name: "Company Profile",
          logo: 'briefcase'
        },
        {
          name: "Logout",
          logo: 'log-out'
        }
      ],
      items2: [
        {
          name: "Switch App",
          logo: 'refresh-cw'
        },
        // {
        //   name: "View my Profile",
        //   logo: 'user'
        // },
        // {
        //   name: "Admin Settings",
        //   logo: 'settings'
        // },{
        //   name: "Company Profile",
        //   logo: 'briefcase'
        // },
        {
          name: "Logout",
          logo: 'log-out'
        }
      ],

      itemWithLogoutOnly: [

        {
          name: "Logout",
          logo: 'log-out'
        }
      ],

      organizations: [],
      showOrganization: false
    }
  },
  created() {
    // getAllCompanies()
    //     .then(res => {
    //       console.log(res)
    //       res.data.forEach(company => viewFile({ url: company.company_logo, context: company, property: 'company_logo' }))
    //       this.organizations = res.data
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       this.loading = false
    //     })
  },
  computed: {
    ...mapGetters('editCompany', ['getCompanyProfile']),
    showData(){
      if(this.$route.query.from){
        return this.items2
      }else {
        return this.items
      }
    },
    getUserDetails() {
      return JSON.parse(sessionStorage.getItem('userData'))
    },
    getFirstName() {
      return this.getUserDetails.name.split(" ")
    },
    apps () {
      const token = sessionStorage.getItem('userToken')
      return [
        {
          name: 'Manage Learning',
          icon: manage_learning,
          route: `${process.env.VUE_APP_ORGANIZATION_URL}?token=${token}`
        },
        // {
        //   name: 'Recruit',
        //   icon: recruitment,
        //   route: `${process.env.VUE_APP_URL}/login?token=${token}`
        // }
      ]
    }
  },
  methods: {
    getInitial(name) {
      let initial = name.substring(0, 1)
      return initial
    },
    handleLogout(type) {
      console.log({type})
      if (type === "Logout") {
        console.log(type)
        logout()
      }
      if (type === "View my Profile") {
        this.$router.push({name:'EditCompanyAdminView'})
      }
      if (type === "Company Profile") {
        this.$router.push({name:'EditCompanyProfileView',params:{tab:'description'}, query:{site_name: this.$route.query.site_name}})
      }
      if (type === "Switch App") {
        this.showOrganization = true
      }
      if (type === "Admin Settings"){
        this.$router.push({name: "EditCompanyAdminView"})
      }
    },
    reloadData() {
      this.$router.push({name:'RecruiterDashboard'})
      window.location.reload();
    },
    getSiteName(companyDetail) {
      let userToken = {...this.getUserDetails}
      userToken.site_name = companyDetail.site_name
      console.log(userToken)
      sessionStorage.setItem("userData", JSON.stringify(userToken));
      this.closeOnContent = true
      this.reloadData()
    },
    isActive (route) {
      const host = route.split('?').shift().split('/')[2]
      return host === window.location.host
    }
  },
}
</script>

<style scoped lang="scss">
.v-menu__content {
  border: 1px solid #F7FCFF;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px #F0F9FF;
  border-radius: 12px !important;
}
.v-dialog{
  box-shadow: none !important;
}
small {
  font-family: DM Sans;
  font-style: normal;
  padding-left: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  color: #9CABB5;

}
.assigned {
  height: auto;
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden;
}

.assigned::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 30px;
}

.assigned::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 20px !important;
  border-radius: 6px;
}
.wrapText{
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.radCard{
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.app--icon {
  background: #008eef;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}
</style>
